import apiEndPoints from "../../apiEndPoints";
import snackbar from "../../helpers/snackbar";
// import snackbar from "../../helpers/snackbar";
import httpReq from "../../services/http";
import channelConstant from "./channelsConstant";

export function createGroup(data, onSuccess, onError) {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        url: apiEndPoints.CREATE_GROUP,
        method: "post",
        data: data,
      });
      if (response?.data?.status === 200 || response?.data?.status === 200) {
        const { chid, name } = response.data;
        dispatch({ type: channelConstant.ADD_CHANNEL, data: { chid, name } });
        return onSuccess({ chid });
      } else {
        return onError();
      }
    } catch (error) {
      return onError();
    }
  };
}

export function fetchGroups(onSuccess, onError) {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        url: apiEndPoints.FETCH_GROUPS,
        method: "get",
        params: { nocache: new Date().getTime() },
      });
      if (response?.status === 200 && response?.data?.status === 200) {
        dispatch({ type: channelConstant.FETCH_CHANNEL, data: response?.data?.groups });
        return onSuccess(response?.data?.groups);
      } else return onError();
    } catch (error) {
      return onError();
    }
  };
}

export function markChannelRead({ chid, isGroup }) {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        method: "post",
        url: apiEndPoints.MARK_READ,
        data: { chid, isGroup },
      });
      if (response?.status === 200 && response?.data?.status === 200) {
        return dispatch({ type: channelConstant.RESET_CHANNEL_COUNT, data: { chid: chid } });
      } else throw "Unable to Mark";
    } catch (err) {
      return snackbar("Unable to mark as read", "ld-bg-warning");
    }
  };
}
