import apiEndPoints from "../apiEndPoints";
import httpReq from "../services/http";

export async function userLookup(key, onSuccess) {
  const response = await httpReq({
    method: "get",
    url: apiEndPoints.USER_LOOKUP,
    params: { key },
  });
  if (response.status === 200 && response?.data?.status === 200) {
    return onSuccess(response?.data?.users);
  }
}
