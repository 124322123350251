import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import CreateGroup from "./CreateGroup";
import Profile from "./Profile";

function SideBar() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sideBar = searchParams.get("sidebar");

  function closeSidebar() {
    searchParams.delete("sidebar");
    return navigate({
      search: searchParams.toString(),
    });
    // return removeQuery("sidebar");
  }
  return (
    sideBar && (
      <section className="ld-sidebar">
        <div className="ld-sb-header">
          <div className="ld-sd-header-wrapper">
            {getterComp[sideBar].title}
            <span className="ld-float-right">
              <i className="ri-close-fill" onClick={closeSidebar}></i>
            </span>
          </div>
        </div>
        <div className="ld-sd-main">
          <SideBarComp type={sideBar} />
        </div>
      </section>
    )
  );
}

function SideBarComp({ type, ...restProps }) {
  const Comp = getterComp[type].comp;
  return <Comp {...restProps} />;
}
const getterComp = {
  cg: { title: "CreateGroup", comp: (restProps) => <CreateGroup {...restProps} /> },
  profile: { title: "Profile", comp: (restProps) => <Profile {...restProps} /> },
};
export default SideBar;
