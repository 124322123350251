/**
 * @author Paravada Naveen Teja <https://www.pnaveenteja.com/>
 * @version 1.0.0
 * @see {@link https://github.com/js-cookie/js-cookie/tree/latest#readme}
 */

import Cookies from "js-cookie";

/**
 * @description Set a cookie
 * @param {String} name Name of the Cookie
 * @param {String} value Value of the Cookie
 * @param {String} expires exprity time of Cookie
 */
function setCookie(name, value, expires) {
  return Cookies.set(name, value, expires);
}

/**
 * @description Get's the cookie value
 * @param {String} name Name of the Cookie saved
 */
function getCookie(name) {
  return Cookies.get(name);
}

/**
 * @description Remove's a cookie
 * @param {String} name Name of the Cookie saved
 * @returns
 */
function removeCookie(name, options = {}) {
  return Cookies.remove(name, options);
}

export default {
  set: setCookie,
  get: getCookie,
  remove: removeCookie,
};
