import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import ProfileImage from "../../ProfileImage";
import snackbar from "../../../helpers/snackbar";
import { createGroup } from "../../../redux/channels/channelsActions";
import { userLookup } from "../../../helpers/userLookup";

function CreateGroup() {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({ name: "", recipients: [] });
  const [users, setUsers] = useState([]);

  //Group Name
  function handleName(ev) {
    return setDetails({ ...details, name: ev.target.value });
  }
  //Add Recipients
  function addUser(user) {
    let userList = details.recipients.slice();
    let findIndex = userList?.findIndex((eachUser) => eachUser._id === user._id);

    if (findIndex === -1) {
      userList.push(user);
      return setDetails({ ...details, recipients: userList });
    }
  }
  //Remove Recipients
  function removeUser(uid) {
    let userList = details.recipients.slice();
    userList.splice(
      userList.findIndex((eachUser) => eachUser._id === uid),
      1
    );
    return setDetails({ ...details, recipients: userList });
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (details.name.length > 0 && details?.recipients.length > 0) {
      const recipients = details.recipients.map((eachUser) => eachUser._id);
      return dispatch(
        createGroup(
          { name: details.name, recipients },
          () => {},
          () => {}
        )
      );
    } else {
      snackbar("Unable to Create Group. All fields mandatory.", "ld-bg-danger");
    }
  }

  const handleSearch = useCallback((ev) => {
    if (ev.target.value !== "" && ev.target.value.length > 2)
      return userLookup(ev.target.value, (users) => setUsers(users));
  }, []);

  return (
    <div className="ld-sd-cg">
      <form onSubmit={handleSubmit}>
        <div className="ld-input-material">
          <label htmlFor="">Group Name</label>
          <input type="text" onChange={handleName} maxLength={30} required />
          <div className="ld-input-bottom ld-float-right">
            {details.name.length}/30 Characters Allowed
          </div>
        </div>
        <div className="ld-cg-search">
          <div className="ld-input-search-post">
            <input
              type="text"
              name="search"
              id="header_search"
              placeholder="Search"
              autoComplete="off"
              onChange={handleSearch}
            />
            <i className="ri-search-line ri-1x"></i>
          </div>
        </div>
        <div className="ld-avatar-users">
          {details?.recipients?.map((eachUser) => (
            <div
              className="ld-avatar-each"
              key={"sd-cg-selected_" + eachUser?._id}
              title={eachUser?.userName}
            >
              <ProfileImage user={eachUser} size="md" />
              <div className="ld-avatar-cancel" onClick={() => removeUser(eachUser?._id)}>
                X
              </div>
            </div>
          ))}
        </div>
        <div className="ld-card-list">
          {users.map((eachUser) => (
            <div
              className="ld-chat-card"
              key={"sd-cg-user_" + eachUser?._id}
              onClick={() => addUser(eachUser)}
            >
              <ProfileImage user={eachUser} />
              <div className="ld-card-content">
                <div className="ld-card-name">{eachUser?.userName}</div>
                <div className="ld-card-message">{eachUser?.email}</div>
              </div>
            </div>
          ))}
        </div>
        {details.recipients.length > 0 && (
          <div className="ld-cg-footer">
            <div className="ld-cg-count">{details.recipients.length} Members Added</div>
            <button type="submit" className="ld-btn-primary ld-btn-rounded">
              Create
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateGroup;
