import produce from "immer";
import usConstant from "./usConstant";

const initialState = {};

const usReducer = produce((state, action) => {
  switch (action.type) {
    case usConstant.FETCH_USER_STATUS:
      state = action.data.reduce((prev, current) => ({ ...prev, [current.uid]: current }), {});
      return state;
  }
}, initialState);

export default usReducer;
