import channelReducer from "./channels/channelsReducer";
import imReducer from "./im/imReducer";
import messagesReducer from "./messages/messagesReducer";
import usReducer from "./us/usReducer";
import userReducer from "./user/userReducer";

export default {
  im: imReducer,
  msg: messagesReducer,
  user: userReducer,
  us: usReducer,
  channels: channelReducer,
};
