/**
 *
 * @author Paravada Naveen Teja <https://www.pnaveenteja.com/>
 * @version 1.0.0
 */

/**
 * @description Snackbar used through out the Application
 * @param {String} text Message to be viewed
 * @param {String} type Error type
 */
export default function snackbar(text, type = "ld-bg-success") {
  let el = document.createElement("div");
  el.className = "snack-bar " + type;
  let y = document?.getElementById("snackbar-container");
  el.innerHTML = text;
  y?.append(el);
  setTimeout(function () {
    el.remove();
  }, 3000);
}
