import React from "react";

/**
 *
 * @param {xs|sm|md|lg|xl|xxl} size
 * @returns
 */
export default function ProfileImage({ user, size }) {
  return user.imgUrl ? (
    <img
      src="./assets/img/dummyprofile.png"
      className={`ld-avatar-${size} ld-rounded ld-float-left`}
      alt=""
    />
  ) : (
    <div className={`ld-avatar ld-avatar-${size}`}>{user?.userName?.[0]?.toUpperCase()}</div>
  );
}

ProfileImage.defaultProps = {
  size: "md",
};
