import apiEndPoints from "../../apiEndPoints";
import snackbar from "../../helpers/snackbar";
import httpReq from "../../services/http";
import userConstant from "./userConstant";

export function myLookup() {
  return async function action(dispatch) {
    try {
      const response = await httpReq({
        url: apiEndPoints.ME_LOOKUP,
        method: "get",
        params: {
          nocache: new Date().getTime(),
        },
      });
      if (response?.status === 200) {
        if (response?.data?.status === 200 || response?.data?.status === 201) {
          dispatch({ type: userConstant.FETCH_USER, data: response?.data?.user });
        } else {
          return snackbar("Some features may not work. Reload.", "ld-bg-danger");
        }
      } else return snackbar("Some features may not work. Reload.", "ld-bg-danger");
    } catch (err) {
      return snackbar("Some features may not work. Reload.", "ld-bg-danger");
    }
  };
}
