import { playMessageReceieved } from "../../helpers/playAudio";
import channelsConstant from "../channels/channelsConstant";
import imConstant from "../im/imConstant";
import messagesConstant from "../messages/messagesConstant";

export default function socketActions(data) {
  return async function action(dispatch) {
    switch (data.scode) {
      case 1001:
        playMessageReceieved();
        dispatch({ type: imConstant.IM_UPDATE_LAST_MESSAGE, data: data });
        dispatch({ type: messagesConstant.ADD_MSG, chid: data.chid, data: data });
        break;
      case 1002:
        dispatch({ type: imConstant.IM_MARK_READ, data: data });
        dispatch({ type: messagesConstant.MSG_MARK_READ, chid: data.chid, data: data });
        break;
      case 1003:
        playMessageReceieved();
        dispatch({ type: channelsConstant.UPDATE_CHANNEL, data: data });
        dispatch({ type: messagesConstant.ADD_MSG, chid: data.chid, data: data });
        break;
      case 1005:
        dispatch({ type: channelsConstant.ADD_CHANNEL, data });
        break;
      default:
        break;
    }
  };
}
