import produce from "immer";
import messagesConstant from "./messagesConstant";

const initialState = {
  cch: "",
  rid: undefined,
};

const messagesReducer = produce((state, action) => {
  switch (action.type) {
    case messagesConstant.FETCH_MSG:
      state = { cch: action.chid, [action.chid]: action.data };
      return state;

    case messagesConstant.ADD_MSG:
      if (state.cch === action.chid) state[action.chid].unshift(action.data);
      return state;

    case messagesConstant.MSG_MARK_READ:
      if (state.cch === action.chid)
        state[action.chid] = state[action.chid].map((eachMsg) => ({ ...eachMsg, isRead: true }));
      return state;

    case messagesConstant.ADD_RID:
      state.rid = action.msg;
      return state;
    case messagesConstant.REMOVE_RID:
      state.rid = undefined;
      return state;

    default:
      break;
  }
}, initialState);

export default messagesReducer;
