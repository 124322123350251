/**
 * @classdesc
 * List of API End Points
 *
 * @component
 * @category Constants
 *
 */

const apiEndPoints = {
  USER_LOOKUP: "/lookup/user",
  ME_LOOKUP: "/lookup/me",
  CREATE_IM: "/channel/createim",
  FETCH_CHANNELS: "/channel/recent-chats",
  MSG: "/messages/single-channel/",
  SEND_MSG: "/messages/send",
  SEND_CH_STATUS: "/messages/sendStatus",
  MARK_READ: "/messages/markRead",
  MSG_FILE_UPLOAD: "/uploadFile",
  MSG_FILE_DOWNLOAD: "/messages/fileDownload",
  CREATE_GROUP: "/channel/create-group",
  FETCH_GROUPS: "/channel/groups",
};

export default apiEndPoints;
