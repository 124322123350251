import produce, { original } from "immer";
import channelsConstant from "./channelsConstant";

const initialState = {};

const channelReducer = produce((state, action) => {
  switch (action.type) {
    case channelsConstant.ADD_CHANNEL:
      state = { [action.data.chid]: action.data, ...state };
      return state;

    case channelsConstant.FETCH_CHANNEL:
      state = action.data.reduce((prev, current) => ({ ...prev, [current.chid]: current }), {});
      return state;

    case channelsConstant.UPDATE_CHANNEL: {
      let currentState = Object.assign({}, original(state));
      let newMsg = {
        ...currentState[action.data.chid],
        unReadCount: action?.msgType === "out" ? 0 : currentState[action.data.chid].unReadCount + 1,
      };
      delete currentState[action.data.chid];
      state = {
        [action.data.chid]: newMsg,
        ...currentState,
      };
      return state;
    }
    case channelsConstant.RESET_CHANNEL_COUNT:
      state[action.data.chid].unReadCount = 0;
      return state;

    default:
      break;
  }
}, initialState);

export default channelReducer;
