import produce, { original } from "immer";
import imConstant from "./imConstant";

const initialState = {};

const imReducer = produce((state, action) => {
  switch (action.type) {
    case imConstant.ADD_IM:
      state = { [action.data.chid]: action.data, ...state };
      return state;

    case imConstant.FETCH_IM:
      state = action.data.reduce((prev, current) => ({ ...prev, [current.chid]: current }), {});
      return state;

    case imConstant.IM_UPDATE_LAST_MESSAGE:
      if (state[action.data.chid]) {
        let currentState = Object.assign({}, original(state));
        let newMsg = {
          ...currentState[action.data.chid],
          lastMessage: action.data,
          unReadCount:
            action?.msgType === "out" ? 0 : currentState[action.data.chid].unReadCount + 1,
        };
        delete currentState[action.data.chid];
        state = {
          [action.data.chid]: newMsg,
          ...currentState,
        };
      } else {
        state = {
          [action.data.chid]: {
            chid: [action.data.chid],
            user: action.data.user,
            lastMessage: action.data,
            unReadCount: 1,
          },
          ...state,
        };
      }
      return state;

    case imConstant.RESET_UNREAD_COUNT:
      state[action.data.chid].unReadCount = 0;
      return state;
    case imConstant.IM_MARK_READ:
      state[action.data.chid].lastMessage = {
        ...state[action.data.chid].lastMessage,
        isRead: true,
      };
      return state;
    default:
      break;
  }
}, initialState);

export default imReducer;
