import produce from "immer";
import userConstant from "./userConstant";

const initialState = {};

const userReducer = produce((state, action) => {
  switch (action.type) {
    case userConstant.FETCH_USER:
      state = action.data;
      return state;

    default:
      break;
  }
}, initialState);

export default userReducer;
