import React from "react";
import { useSelector } from "react-redux";
import cookiesContants from "../../../constants/cookiesContants";
import cookies from "../../../helpers/cookies";
import { mySocket } from "../../../socket/socketEntry";
import ProfileImage from "../../ProfileImage";

export default function Profile() {
  const user = useSelector((state) => state.user);
  function logout() {
    mySocket.unsubscribe(user.uid);
    cookies.remove(cookiesContants.sessionId, {
      path: "/",
      domain: ".konzasoftwar.com",
    });

    return (window.location = process.env.LANDING_URL);
  }
  return (
    <div className="ld-mt-20">
      <div className="ld-flex-center">
        <ProfileImage user={user} size="lg" />
        <div className="ld-ml-30">{user.userName}</div>
      </div>
      <button
        className="ld-btn-primary ld-btn-rounded ld-mt-30 ld-float-right"
        onClick={logout}
      >
        Logout
      </button>
    </div>
  );
}
