import { lazy } from "react";

const Chat = lazy(() => import("@/containers/Chat"));
const routePaths = [
  {
    path: "/*",
    title: "Chat",
    index: true,
    element: <Chat />,
  },
];

export default routePaths;
